<template>
    <div>
        <van-popup
            v-model="show"
            :safe-area-inset-bottom="true"
            :overlay="showCover"
            position="bottom"
            @close="closePopup"
            :close-on-click-overlay="false"
            :lock-scroll="true"
            :closeable="true"
            round
        >
            <div class="coupon-box content">
                <div class="coupon-title">选择优惠券</div>
                <div v-if="list.length || unavailableList.length" class="coupon-list">
                    <div class="item" v-for="(item) in list" :key="item.id">
                        <div v-show="item.id === selectedCouponId" class="active-item"></div>
                        <div @click="selectedC(item)" class="item-i" :class="{'active': item.active}">
                            <div class="item-r">
                                <img class="arrow" @click.stop="openTips(item)" :class="{'rotate': item.active}" src="https://img.chaolu.com.cn/MINI/icon/bottom.png" alt="">
                                <div class="coupon-name ellipsis">{{ item.name }}</div>
                                <div class="type-name" v-if="item.tags && item.tags.length">
                                    <span class="row-center-center" v-for="v in item.tags" :key="v">{{v}}</span>
                                </div>
                                <div class="coupon-date coupon-time">{{ item.time }}</div>
                                <div class="coupon-date">详细使用说明</div>
                            </div>
                            <div class="item-l col-center-center">
                                <div v-if="item.styleType === 2 || item.styleType === 3">免单</div>
                                <div v-else><span>¥</span>{{ item.discount }}</div>
                                <!-- 最多优惠 或 满多少减多少 -->
                                <span v-if="item.styleType === 2 || item.styleType === 3" class="limit-int">最多优惠{{item.discount}}元</span>
                                <span dir="rtl" v-else class="limit-int">满{{item.spend}}元可用</span>
                            </div>
                        </div>
                        <div class="instructions" :class="{'active': item.active, 'close': item.active !== undefined && !item.active}">
                            <p>{{ item.remarks }}</p>
                        </div>
                    </div>



                    <p v-if="unavailableList.length" class="no-tips">以下优惠券暂不适用</p>
                    <div class="item gary" v-for="(item) in unavailableList" :key="item.id">
                        <div  class="item-i" :class="{'active': item.active}">
                            <div class="item-r">
                                <img class="arrow" @click.stop="openTips(item)" :class="{'rotate': item.active}" src="https://img.chaolu.com.cn/MINI/icon/bottom.png" alt="">
                                <div class="coupon-name">{{ item.name }}</div>
                                <div class="type-name" v-if="item.tags && item.tags.length">
                                    <span class="row-center-center" v-for="v in item.tags" :key="v">{{v}}</span>
                                </div>
                                <div class="coupon-date coupon-time">{{ item.time }}</div>
                                <div class="coupon-date" style="color:#ED5C42 " v-if="item.unavailableReason">{{ item.unavailableReason }}</div>
                                <div class="coupon-date" v-else>详细使用说明</div>
                            </div>
                            <div class="item-l col-center-center">
                                <div><span>¥</span>{{ item.discount }}</div>
                            </div>
                        </div>
                        <div class="instructions" :class="{'active': item.active, 'close': item.active !== undefined && !item.active}">
                            <p>{{ item.remarks }}</p>
                        </div>
                    </div>
                </div>
                <div v-else  class="coupon-list empty">
                    <img src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/WeChat/defaultPic/empty.png" alt="">
                    <div>暂无优惠券</div>
                </div>
                <div @click="confirmC()" class="pay-btn row-center-center fw6">确定</div>
            </div>
        </van-popup>
    </div>
</template>
<script>
import {mapState} from 'vuex'
import wx from "weixin-js-sdk";

export default {
    props: {
        showCover: {
            type: Boolean,
            default: true
        },
        showCoupon: {
            type: Boolean,
            default: false
        },
        dateTips: {
            type: [String, Number],
            default: 0
        },
        couponId: {
            type: String,
            default: ''
        },
        discount: {
            type: [String, Number],
            default: 0
        },
        // 券列表
        list: {
            type: Array,
            default() {return []}
        },
        // 不可用券列表
        unavailableList: {
            type: Array,
            default() {return []}
        },
    },
    data() {
        return {
            show: false,
            // 支付参数
            selectedDiscount: 0,
            selectedCouponId: '',
            expireDayTime: '',
            // submitMoney: 0 // 最终支付金额
        };
    },
    methods: {
        selectedC(v) {
            if(this.selectedCouponId === v.id){
                this.selectedDiscount = 0
                this.selectedCouponId = ''
            }else{
                this.selectedDiscount = v.discount
                this.selectedCouponId = v.id
                this.expireDayTime = v.expireDayTime
            }
        },
        confirmC() {
            this.list.map(item => {
                item.active = undefined
            })
            this.show = false
            this.$emit('confirm', {id: this.selectedCouponId, discount: this.selectedDiscount, expireDayTime: this.expireDayTime})
        },
        openTips(v) {
            v.active = !v.active
            this.$forceUpdate()
        },
        closePopup() {
            this.list.map(item => {
                item.active = undefined
            })
            this.$emit('update:showCoupon', false)
        }
    },
    watch: {
        showCoupon(val) { // 显示隐藏
            this.show = val
            console.log(this.couponId, 99999999)
            if(val){
                if(this.discount)
                    this.selectedDiscount = this.discount
                // if(this.couponId)
                    this.selectedCouponId = this.couponId
                if(this.dateTips)
                    this.expireDayTime = this.dateTips
            }
        }
    },
};
</script>
<style lang="less" scoped>
.instructions {
    //height: 0;
    width: 692px;
    margin: -16px auto 0;
    overflow: hidden;
    max-height: 0;
    background-color: white;
    box-sizing: border-box;
}

.instructions p {
    padding: 24px;
    font-size: 20px;
    color: #9AA1A9;
    line-height: 32px;
    white-space: pre-wrap;
}

.instructions.active {
    animation: open .5s forwards;
}

.instructions.close {
    animation: close .5s forwards;
}

@keyframes open {
    0% {
        max-height: 0;
    }
    100% {
        max-height: 120px;
        overflow-y: scroll;
    }
}

@keyframes close {
    0% {
        max-height: 120px;
    }
    100% {
        max-height: 0;
        overflow-y: hidden;
    }
}

.content {
    padding: 0 0 32px;
    box-sizing: border-box;
    overflow-y: scroll;
}

.coupon-box {
    background: #F5F5F5;
    padding-bottom: 0;
    height: 1032px;
}

.pay-btn {
    width: 686px;
    height: 96px;
    background: #FFDE00;
    border-radius: 8px;
    color: #242831;
    font-size: 32px;
    margin: 36px auto 38px;
}
.coupon-item{
    color: #242831;
    font-size: 24px;
    font-weight: bold;
    padding: 32px 0 18px;
    border-top: 1px solid #E6E6E6;
}
.coupon-item-tips{
    color: #242831;
    font-size: 24px;
}
.coupon {
    height: 100px;
    box-sizing: border-box;
    //border-top: 1px solid #E6E6E6;
    border-bottom: 1px solid #E6E6E6;
    background: url("https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/cash-coupon/arrow-right.png") no-repeat center right;
    background-size: 24px 24px;
    padding-right: 34px;
    margin-top: 12px;
    .coupon-price-red{
        color: #FB732E;
    }
    .s2 {
        font-family: BebasNeueBold;
        font-size: 32px;
        font-weight: bold;
        color: #F03C18;
        margin-right: 4px;
    }
    .s3 {
        font-family: BebasNeueBold;
        color: #F03C18;
        font-size: 40px;
        font-weight: bold;
    }
}
.coupon-title {
    font-weight: bold;
    color: #242831;
    font-size: 36px;
    //padding-left: 56px;
    height: 112px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
    //background: url("https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/cash-coupon/arrow-left.png") no-repeat center left;
    //background-size: 40px 40px;
}

.coupon-list {
    height: calc(100% - 314px);
    overflow-y: scroll;
    &.empty {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        img {
            width: 160px;
            height: 160px;
        }
        div {
            margin-top: 12px;
            font-size: 24px;
            color: #999;
        }
    }
}

.coupon-list::-webkit-scrollbar {
    display: none;
}

.price {
    padding: 28px 0 32px;
}

.price > div .s1 {
    text-decoration: line-through;
    color: #6C727A;
    line-height: 28px;
    font-size: 24px;
    margin-right: 16px;
}

.price > div .s2 {
    font-family: BebasNeueBold;
    color: #242831;
    font-size: 24px;
    font-weight: bold;
}

.price > div .s3 {
    font-family: BebasNeueBold;
    color: #242831;
    font-size: 40px;
    font-weight: bold;
}

.left {
    font-size: 24px;
    color: #242831;
    line-height: 28px;
}

.title {
    color: #242831;
    font-size: 36px;
    font-weight: bold;
    padding: 48px 0;
}

.pay-type {
    color: #242831;
    font-size: 24px;
    font-weight: bold;
    padding: 32px 0 8px;
}
.wechat, .alipay {
    background: url("https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/cash-coupon/normal.png") no-repeat center right;
    background-size: 40px 40px;
    padding: 24px 0;

    &.active {
        background-image: url("https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/cash-coupon/active.png");
    }

    div {
        display: flex;
        align-items: center;
    }

    img {
        width: 40px;
        height: 40px;
        margin-right: 16px;
    }
}

.coupon-box .item {
    //background-color: white;
    margin-bottom: 16px;
    position: relative;
    .active-item{
        background: url("https://img.chaolu.com.cn/ACT/sell-membership-2022/renew/select_icon.png") center center no-repeat;
        background-size: 100% 100%;
        position: absolute;
        left: 30px;
        top: 14px;
        width: 48px;
        height: 48px;
        box-sizing: border-box;
    }
}

.coupon-box .item-i {
    display: flex;
    justify-content: space-between;
    height: 270px;
    //align-items: center;
    padding: 32px;
    box-sizing: border-box;
    background-size: 100% 100%;
    background-image: url(https://img.chaolu.com.cn/ACT/sell-membership-2022/renew/voucher_bg.png);
}
.coupon-box .gary{
    .item-r .coupon-name{
        color: #787A80;
    }
    .item-i {
        background-image: url(https://img.chaolu.com.cn/ACT/sell-membership-2022/renew/voucher_bg_no.png);
    }
    .type-name span{
        background: #FF9C80;
    }
    .item-r .coupon-date.coupon-time{
        color: #787A80;
    }
}
.coupon-box .item-l {
    width: 192px;
    height: 100%;
    color: white;
    font-size: 20px;
    flex-grow: 1;
}

.coupon-box .item-l > div:first-child {
    font-family: BebasNeueBold;
    font-size: 80px;
}

.coupon-box .item-l > div:first-child span {
    font-size: 36px;
}

.coupon-box .item-r {

    box-sizing: border-box;
    padding-left: 26px;
    position: relative;
    width: 440px;
    flex-shrink: 0;
}

.coupon-box .item-r .coupon-tabs {
    display: flex;
    flex-wrap: wrap;
    margin-top: 12px;


}

.coupon-box .item-r .coupon-tabs span {
    padding: 4px 8px;
    font-weight: bold;
    color: #FB732E;
    font-size: 18px;
    border: 1px solid #FB732E;
    border-radius: 4px;
    margin-bottom: 8px;
    margin-right: 8px;
}

.coupon-box .item-r .coupon-date {
    margin-top: 38px;
    font-size: 20px;
    line-height: 23px;
    color: #9AA1A9;
    position: absolute;
    left: 26px;
    bottom: 8px;
    &.coupon-time{
        bottom: 48px;
        color: #242831;
    }
}

.coupon-box .item-r .coupon-name {
    line-height: 28px;
    font-weight: bold;
    font-size: 28px;
    padding-top: 24px;
}
.type-name{
    display: flex;
    align-items: center;
    margin-top: 16px;
    span{
        height: 32px;
        padding: 0 16px;
        background: #FF7047;
        border-radius: 16px;
        color: #FFFFFF;
        font-size: 20px;
        & + span{
            margin-left: 16px;
        }
    }
}
.goodsNum {
    margin-top: 48px;
}

.coupon-box .item-r .arrow {
    position: absolute;
    right: 4px;
    bottom: -8px;
    width: 24px;
    height: 24px;
    transition: transform .5s;
    padding: 20px;
}

.coupon-box .arrow.rotate {
    transform: rotateZ(180deg);
}
.c-container{
    margin-top: 48px;
}


.no-tips{
    margin: 64px auto 20px;
    font-size: 24px;
    line-height: 24px;
    font-weight: bold;
    color: #9AA1A9;
    text-align: center;
}
.limit-int {
    color: #fff;
    font-size: 20px;
    margin-left: 6px;
    letter-spacing: 1px;
    margin-top: 12px;
    line-height: 20px;
}
</style>

